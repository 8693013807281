import React, { useState } from 'react';

import { DiGithubAlt } from "react-icons/di";
import { MdOutlineWorkHistory } from "react-icons/md";
import { SiPinetwork } from "react-icons/si";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";


import {Card,CardBody,CardTitle,CardSubtitle,CardText} from 'reactstrap'
//import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from '../Carousal'
import { getStorage, ref, getMetadata } from "firebase/storage";

import Courses from './Courses';
import "./home.css";
import "./styles.css"


const Home = () => {
  return (
    <div>
      <section class="section__container service__container" id="service">
      <h2 class="section__header">Why Choose Us?</h2>
      <p><b>Unlock your potential with our expert-led affiliate marketing training</b></p>
      <div class="service__grid">
        <div class="service__card">
          
          <span><DiGithubAlt /><i class="ri-window-fill"></i></span>
          <h4>Proven Expertise</h4>
        </div>
        <div class="service__card">
          <span><MdOutlineWorkHistory /><i class="ri-smartphone-line"></i></span>
          <h4>5+ Years Experience</h4>
        </div>
        
        <div class="service__card">
          <span><SiPinetwork /><i class="ri-seo-line"></i></span>
          <h4>Hand on Learning</h4>
          
        </div>
        <div class="service__card">
          <span><MdOutlineWorkspacePremium /><i class="ri-window-fill"></i></span>
          <h4>Success Stories</h4>
        </div>
        
       </div> 
    </section>

    <div>
      <Courses />
      
    </div>
    <div className="carousel">
      <h1>Industry<p>Demanding</p>Courses </h1>
      <div className="paragraph">
      <b>Our team consists of seasoned affiliate marketers with years of hands-on experience</b>
      </div>
      
    <Carousel />
    </div>


   <div className="camel"> 
   
   
  <img className="about"
    alt="Sample"
    src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2FJamil.jpg?alt=media&token=fc25438d-2d1f-434f-b2e2-4b1ea97e80b7"
  />
 <h2 className='abdt'>MD and Founder</h2>
 <h6 className='nmr'>Mr.Jamil Khan</h6>
 <p className='priyem'>He has Work <strong>5 Years</strong> in Affiliate Marketing Industry and Grow his career and team also achive A Brand Ambessedor Position</p>
    
</div>
    
  
  


  <div className='faq'>
    <h2 className='fa'>FAQs ?</h2>
  <div>
    <ul className='under'>
      <li className='q1'><strong>1. What is Taj Millionaire Marketing?</strong>
        
      </li>
      <li>
        <strong>Answer:</strong>Taj Millionaire Marketing is E Learnig Platform that help to Learn Skills in Marketing, Technology sectors.
      </li>
      <li className='q2'>
        <strong>2. How do I get started with this Platform?</strong>
      </li>
      <li>
        <strong>Answer:</strong> Just Signup on our Platform and wait for 2-3 houre for account Activation.
      </li>
      <li className='q3'><strong>3.How do I track my affiliate sales and commissions?</strong>
      </li>
      <li>
        <strong>Answer:</strong>Yes you can track your earning from Dashboard.
      </li>
      <li className='q4'><strong>4.What are the Levels?</strong></li>
      <li>
        <strong>Answere:</strong>Yes we have Levels Bronze,Silver,Gold, Platinum and Diamond.
      </li>
    </ul>
  </div>
   
  </div>
</div>


   
    
    
  
  

  );
};
export default Home