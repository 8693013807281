import React from "react";
import './About.css';

export const About = () => {
  return (
    <div className="who">
     <h1 className="abu">About us</h1> 
     <h4 className="web">Welcome to Taj Millionaire Marketing</h4>
      <p className="pera">At Taj Millionaire Marketing, we are passionate about empowering individuals to achieve financial freedom through affiliate marketing. Founded in 2024, our mission is to provide comprehensive resources, training, and support to help you navigate the exciting world of affiliate marketing.

</p>
<div className="=who1">
  <h2 className="abu1">Who We Are</h2>
  <p className="para">We are a team of experienced marketers, content creators, and industry experts dedicated to sharing our knowledge and expertise. With years of hands-on experience, we understand the challenges and opportunities that come with affiliate marketing, and we’re here to guide you every step of the way.

</p>
</div>
<div className="directors">
 <img className="rsmtr" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2Fjamil1.jpg?alt=media&token=6f0ca4bc-fc3b-489d-a3c2-71a394b35a5b"></img>
 <h2 className="ceobr">MD And Founder</h2>
 <h4 className="ceorb">Mr. Jamil Khan</h4>
 <img className="rsmtr" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2FTayyab.jpg?alt=media&token=cd2e1a56-ca84-4cb5-87b8-acfab07b1da7"></img>
 <h2 className="ceobr">CEO & Board of Director</h2>
 <h4 className="ceorb">Mr.Tayyab Khan</h4>

 
 <img className="rsmtr" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2FTiwari.jpeg?alt=media&token=1488a366-0ef4-44db-b893-e81875f5c912"></img>
 <h2 className="ceobr">Training Manager</h2>
 <h4 className="ceorb">Mr.Mohit Tiwari</h4>
 <p className="prgraph">He has 2 Years of Experience and Meta Ads expert and Trained 500+ Student And Currently running a Ad Agency and Digital Marketing Solution</p>

 </div>

 
</div>
    
  );
};
