
import './Commission.css';
const Commission = () => {
    // Sample data
    const data = [
        { id: 1, name: 'Mini Package', price: 599, com: '400',pass:'50' },
        { id: 2, name: 'Taj Premium Package', price: 1299, com: '800',pass:'100' },
        { id: 3, name: 'Database Package', price: 2499, com: '1700',pass:'200' },
        { id: 4, name: 'Mastry Package', price: 4399, com: '3100',pass:'320' },
        { id: 5, name: 'Marketing Package', price: 7499, com: '5500',pass:'400' },
        { id: 6, name: 'Super Mastry Package', price: 12999, com: '7500',pass:'999' },
        { id: 7, name: 'Branding Package', price: 24999, com: '17000',pass:'1999' },
    ];

    return (
        <div className='back'>
            
            <h2>Commission Structure</h2>
            <table>
                <thead>
                    <tr>
                        <th>Sr</th>
                        <th>Package Name</th>
                        <th>Price</th>
                        <th>Commission</th>
                        <th>Passive Income</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.price}</td>
                            <td>{user.com}</td>
                            <td>{user.pass}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
        </div>
        
    );
};

export default Commission;
