import React from "react";
import './Contact.css';
import { MdPhonelink } from "react-icons/md";
import { IoMailUnread } from "react-icons/io5";
import { FaLocationArrow } from "react-icons/fa";


export const Contact = () => {
  return (
    <div>
      <h1 className="cont"> Contact Us</h1>
      <div className="phone ">
        <div className="ph"> 
          <img src="./contact/phone.png"></img>
          <h4 className="no">+919669132900</h4>
        </div>
      </div>
      <div className="mail ">
        <div className="em"> 
          <img src="./contact/mail.png"></img>
          <h4 className="no">tajmillinairemarketingtaj@gmail.com</h4>
        </div>
      </div>

      <div className="add ">
        <div className="am"> 
          <img src="./contact/location.png"></img>
          <h4 className="ad">Pipakota District Kannod M.P</h4>
        </div>
      </div>
    </div>
  );
};
