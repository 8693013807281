import React, { useEffect, useState } from 'react'
import './Profile.css'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { doc, getDoc, query, where, collection, getDocs,setDoc } from "firebase/firestore";
import { sendEmailVerification, getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth'
import { useAuth } from '../context/UserAuthContext'
import {deleteObject,getDownloadURL,ref,uploadBytesResumable} from "firebase/storage";
import DeleteIcon from '@mui/icons-material/Delete';
import { storage, db } from '../firebase.config';
const Profile = () => {
    //users collection initiliaze start 'I use this because i want to fetch affiliate id and sponsor id from users '
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //users collection end you can remove this 

    const { verifyemail } = useAuth()
    const auth = getAuth()
    const [userids, setuserid] = useState("")
    const [Userprofile, setUserprofile] = useState()
    const [show, setshow] = useState(false)
    const { profileInformation } = useAuth()
    const [image, setimage] = useState("")
    const [profile, setprofile] = useState({
        
        name: "",
        mobile: "",
        city: "",
        address: "",
        holder: "",
        account: "",
        bank: "",
        ifsc: "",
        branch: "",
        upi: "",
        imageurl: "",
        uid: ""
    })
    const [verify, setverify] = useState(true)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(user.uid + "first")
                const check = user.emailVerified
                setverify(check)
                setuserid(user.uid)
                getData()

            } else {
            }
        });
        console.log(auth?.currentUser?.emailVerified + "is email is verify")
    }, [verifyemail, auth?.currentUser?.emailVerified])
    const getData = async () => {
        const q = query(collection(db, "profile"), where("uid", "==", auth?.currentUser?.uid));
        console.log({ userids } + "ingetdata")
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setUserprofile(doc.data())
        });
    }
    console.log(auth?.currentUser?.emailVerified)
    const emailVerify = async () => {
        try {
            await sendEmailVerification(auth.currentUser)
            auth.languageCode = 'it';
            alert("email send ")

        } catch (error) {
            alert(`${error}`)
        }
    }
    const handler = (e) => {
        const { name, value } = e.target;
        setprofile(pre => {
            return {
                ...pre,
                ["uid"]: auth.currentUser?.uid,
                [name]: value
            }
        })
    }
    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            const profileInformation = doc(db, "profile", auth.currentUser.uid);
            
            await setDoc(profileInformation, profile, { merge: true })
            alert("Profile Update Successfully")
            setprofile({
                
                name: "",
                mobile: "",
                city: "",
                address: "",
                holder: "",
                account: "",
                bank: "",
                ifsc: "",
                branch: "",
                upi: "",
                imageurl: "",
                uid: ""
            });
            setshow(false)
        } catch (error) {
            alert("someError")
            console.log(error)
        }
    }
    //Commented by keshav
     const updateProfileuser = () => {
          
    }


    const clear = () => {
        setprofile({
            
            name: "",
            mobile: "",
            city: "",
            address: "",
            holder: "",
            account: "",
            bank: "",
            ifsc: "",
            branch: "",
            imageurl: "",
            upi: "",
        })
        setshow(false)
    } 
     const uploudImage = (e) => {
        const imageref = e.target.files[0];
        const storgeref = ref(storage, `images/${Date.now()}-${imageref.name}`);
        const uploadimage = uploadBytesResumable(storgeref, imageref);
        uploadimage.on(
            "state_changed",
            (onSnapshot) => {
                const progress =
                    (onSnapshot.bytesTransferred / onSnapshot.totalBytes) * 100;
                console.log(progress)

            },
            (error) => {
                console.log(error)
            },
            () => {
                getDownloadURL(uploadimage.snapshot.ref).then((downloadURL) => {
                    setimage(downloadURL)
                    setprofile(pre => {
                        return {
                            ...pre,
                            imageurl: downloadURL
                        }
                    })
                });
            }
        );
    };

    const removeimage = () => {
        console.log(profile.imageurl)
        const deleteRef = ref(storage, image);
        deleteObject(deleteRef)
            .then(() => {
                alert("deleted")
                setimage("")
            })
            .catch((error) => {
                console.log(error)
            });
    } 

    //fetch from users collection field Sponsor information and Affiliate id 

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser; // Get the current user
      if (user) {
        const userId = user.uid; // Get the UID of the current user
        const docRef = doc(db, 'users', userId); // Replace 'users' with your actual collection name

        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const { uniqueId, nameToSearch, selectedName } = docSnap.data(); // Replace with the fields you need
            setUserData({ uniqueId, nameToSearch, selectedName });
          } else {
            setError('No user data found!');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        setError('User not logged in');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  //fetching sponsor data Ending the Fuction 

    return (
        <div className='container'>         
            
                <form onSubmit={submitHandler}
                    style={{
                        display: `${!show ? "none" : ""}`
                    }}

                >
                    <div className='innerBoxEdit'>


                    

                            <div className='box1'>
                                <div className='imageconatiner'>
                                    {!image ? (<label className='imagebox'>
                                        <div className='icons'>
                                            <input
                                                type="file"
                                                name="imageurl"
                                                style={{ visibility: "hidden" }}
                                                accept="image"
                                                onChange={uploudImage}
                                            ></input>
                                            <CloudUploadIcon className='size' fontSize="large">
                                            </CloudUploadIcon>
                                        </div>
                                    </label >) : (
                                        <div className='imagebox'>
                                            <img
                                                src={image}
                                                alt="uploaded image"
                                                className="imagebox"
                                            />
                                            <button className='deleteButton' type='reset' onClick={removeimage}>
                                                <DeleteIcon></DeleteIcon>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                    </div>
  
                            
                            
                        
                            <div className='box1'>
                            <h2 className='h2' style={{ fontSize: "15px" }}>Update Your Profile!</h2>
                                
                                <div className='littleBox'>
                                    <h3 className='h3'>Name</h3>
                                    <input type="text" className='inputdata' placeholder='enter your Name' value={profile.name} name="name" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Mobile No </h3>
                                    <input type="text" className='inputdata' placeholder='enter your Mobile' value={profile.mobile} name="mobile" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>City</h3>
                                    <input type="text" className='inputdata' placeholder='enter your city' value={profile.city} name="city" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Address</h3>
                                    <input type="text" className='inputdata' placeholder='enter your Address' value={profile.address} name="address" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Account Holder Name</h3>
                                    <input type="text" className='inputdata' placeholder='enter holder name' value={profile.holder} name="holder" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Account Number</h3>
                                    <input type="text" className='inputdata' placeholder='enter account number' value={profile.account} name="account" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Bank Name</h3>
                                    <input type="text" className='inputdata' placeholder='enter bank name' value={profile.bank} name="bank" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>IFSC</h3>
                                    <input type="text" className='inputdata' placeholder='enter ifsc' value={profile.ifsc} name="ifsc" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>Branch</h3>
                                    <input type="text" className='inputdata' placeholder='enter branch name' value={profile.branch} name="branch" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                                    <h3 className='h3'>UPI ID</h3>
                                    <input type="text" className='inputdata' placeholder='upi id or phonepay' value={profile.upi} name="upi" onChange={handler}></input>
                                </div>
                                <div className='littleBox'>
                           
                                    <div className='verifiable'>
                                        <h3 className='h3'>Email</h3>

                                    </div>
                                    
                                </div>
                            
                        
                        <button type='submit' className='buttonsimple'>save</button>
                        <button type='reset' onClick={clear} className='buttonsimple'>cancel</button>
                    </div>
                    </div>                    
                </form>
  
  
                 


                <div className='heading'>   
                        <h3 className='greeting'>Welcome</h3>
                        <div className='hellow'>{Userprofile?.name}</div>

                         {/* Fetch the profile image in dashboard */}
                <div className='innerCintainer'>
                    
                        <img className='pro' src={Userprofile?.imageurl || "https://"} />
                    
                </div> 

                          <div className='sponser'>              
                          <h6 className='sponserr'>Sponsor Information</h6>
                         <div className='littleBox'>
                            <h3 className='h3'>Name</h3>
                            <div className='text'>{userData.selectedName}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Username</h3>
                            <div className='text'>{userData.nameToSearch}</div>
                        </div>

                        </div>                       
                        <h6 className='h6'>Personal Information</h6>
                        
                        <div className='littleBox'>
                            <h3 className='h3'>Name</h3>
                            <div className='text'>{Userprofile?.name}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Affiliate ID</h3>
                            <div className='text'>{userData.uniqueId}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Mobile No. </h3>
                            <div className='text'>{Userprofile?.mobile}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>City</h3>
                            <div className='text'>{Userprofile?.city}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Address</h3>
                            <div className='text'>{Userprofile?.address}</div>
                        </div>
                        <div className='littleBox'>
                            <div className='verifiable'>
                                <h3 className='h3'>Email</h3>

                                {
                                    verifyemail && verifyemail ?
                                        (
                                            <VerifiedUserIcon style={{ fontSize: 12, color: "green", marginBottom: "10px" }} fontSize={"small"}></VerifiedUserIcon>
                                        ) :
                                        (<p className='verify'>
                                            <button className='buttonverify' onClick={emailVerify}> Verify</button>
                                        </p>)
                                }
                                
                            </div>
                            <p className='text'>{auth.currentUser?.email}</p>
                        </div>
                        {/* this is KYC section addes extra */}
                         
                         <h6 className='h6'>Kyc Status</h6>
                         <div className='littleBox'>
                            <h3 className='h3'>Account Holder Name</h3>
                            <div className='text'>{Userprofile?.holder}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Account Number</h3>
                            <div className='text'>{Userprofile?.account}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Bank Name</h3>
                            <div className='text'>{Userprofile?.bank}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>IFSC</h3>
                            <div className='text'>{Userprofile?.ifsc}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>Branch</h3>
                            <div className='text'>{Userprofile?.branch}</div>
                        </div>
                        <div className='littleBox'>
                            <h3 className='h3'>UPI ID</h3>
                            <div className='text'>{Userprofile?.upi}</div>
                        </div>
                         
                
               
            
             
            </div>

            <button className='profileButton' onClick={() => { setshow(pre => !pre) }}>Update Profile</button>
        </div>
    )
}

export default Profile