import React from 'react'
import {Outlet} from 'react-router-dom'
import { useAuth } from '../context/UserAuthContext';
import Header from './Header';
import NavBar from './NavBar';
import Footer from './Footer';



const Public = ({ showHead = false }) => {
    const { currentUser } = useAuth();
    
    return (
        <>
                    {showHead && !currentUser && <Header />}

            <main>
            <NavBar />
            
            {/* Protected Route is use for not access without login any dashboard page */}
            
            {/*Outlet is used for all the Components in LAyout Route is only access by Layout */}
            <Outlet></Outlet>
            
            <Footer />
            </main>
            
            

        </>
    )
}

export default Public;    