import React, {useState } from 'react';

import NavBar from "./component/NavBar";
import Footer from './component/Footer';
import Login from './component/Login';
import Signup from './component/Signup';
import Home from "./component/Pages/Home";
import { About } from "./component/Pages/About";
import Courses  from "./component/Pages/Courses";
import { Contact } from "./component/Pages/Contact";
import Public from './component/Public';
import Enroll from './component/Enroll';
import Profile from './component/Profile';
import Earning from './component/Earning';
import Commission from './component/Commision';

import UserAuthContext from './context/UserAuthContext';
import Admin from './component/Admin';

import {BrowserRouter,Router,Route,Routes} from "react-router-dom";
import Layout from './component/Layout';
import Userhome from './component/Userhome';
import Company from './component/Company';

function App() {
  
  return (
    <BrowserRouter>
    
      
      <UserAuthContext>
        
  <Routes>
  
  
  <Route path="/" element={<Layout showNavBarAndFooter={false}/>}>
    <Route path='/userhome' element={<Userhome />}></Route>
    <Route path='/dashboard' element={<Enroll />}></Route>
    <Route path='/profile' element={<Profile />}></Route>
    <Route path='/earning' element={<Earning />}></Route>
    <Route path='/commission' element={<Commission />}></Route>
    <Route path='/company_info' element={< Company/>}></Route>
    </Route>



    
    
  <Route path="/" element={<Public />}>
  <Route path="/home" element={<Home /> } />
  <Route path="/about" element={<About />} />
  <Route path="/courses" element={<Courses />} />
  <Route path="/contact" element={<Contact />} />
  <Route path='/login' element={<Login />} />
  <Route path='/signup' element={<Signup />} />
  <Route path='/find' element={<Admin />} />
  </Route>
  </Routes>
       
      </UserAuthContext>
      
      
      </BrowserRouter>
  );
}

export default App;