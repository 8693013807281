import React from 'react'
import {Outlet} from 'react-router-dom'
import { useAuth } from '../context/UserAuthContext';
import NavBar from './NavBar';
import Footer from './Footer';
import Header from './Header'
import ProtectedRoute from './ProtectedRoute'

const Layout = ({ showNavBarAndFooter = true }) => {
    const { currentUser } = useAuth();
    
    return (
        <>
                    {showNavBarAndFooter && !currentUser && <NavBar />}
                    
            <main>
            <Header />
            
            {/* Protected Route is use for not access without login any dashboard page */}
            <ProtectedRoute />
            {/*Outlet is used for all the Components in LAyout Route is only access by Layout */}
            <Outlet></Outlet>
            

            </main>
            
            {showNavBarAndFooter && !currentUser && <Footer />}

        </>
    )
}

export default Layout     