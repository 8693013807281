import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousal.css'
function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="crc1">
          <img src="./img/1.jpg"></img>
          
        </div>
        <div className="crc2">
        <img src="./img/2.png"></img>
        </div>
        <div className="crc3">
        <img src="./img/3.jpg"></img>
        </div>
        <div className="crc4">
        <img src="./img/4.jpg"></img>
        </div>
        <div className="crc5">
        <img src="./img/5.jpg"></img>
        </div>
        <div className="crc6">
        <img src="./img/6.jpg"></img>
        </div>
        
      </Slider>
    </div>
  );
}

export default Carousel;
