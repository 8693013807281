// src/EarningsDashboard.js
import './Earning.css';
import { FaRupeeSign } from "react-icons/fa";
import { GiNailedHead } from "react-icons/gi";
import { MdCardMembership } from "react-icons/md";


import React, { useEffect, useState } from 'react'
import { doc, getDoc, query, where, collection, getDocs,setDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { storage, db } from '../firebase.config';
const Earning = () => {
    const auth = getAuth()
    const [userids, setuserid] = useState("")
    const [Userprofile, setUserprofile] = useState()
    const [show, setshow] = useState(false)
    
    
    const [profile, setprofile] = useState({
        package: "",
        totalEarnings: "",
        mobile: "",
        city: "",
        address: "",
        
    })
    const [verify, setverify] = useState(true)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(user.uid + "first")
                const check = user.emailVerified
                setverify(check)
                setuserid(user.uid)
                getData()

            } else {
            }
        });
        console.log(auth?.currentUser?.emailVerified + "is email is verify")
    })
    const getData = async () => {
        const q = query(collection(db, "profile"), where("uid", "==", auth?.currentUser?.uid));
        console.log({ userids } + "ingetdata")
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setUserprofile(doc.data())
        });
    }
    console.log(auth?.currentUser?.emailVerified)
    
   


    const clear = () => {
        setprofile({
            package: "",
            totalEarnings: "",
            mobile: "",
            city: "",
           
        })
        setshow(false)
    }

  return (
    <div className="dashboard">
      <h6 className='heading_dash'> <strong>Dashboard</strong></h6>
      <div className='pack1'><p className='grt'><strong>Welcome</strong></p><h2 className='pack'>{Userprofile?.name}</h2></div>
       <div className='carding'>
       
        <img className='earnimag' src={Userprofile?.imageurl || "https://"} />
        <div className='bux'>
        <div className='ex' >
          <p className='hony'><FaRupeeSign /></p>
          
        </div>
        <div className='subex'>
        <h1 className='inside'><FaRupeeSign />{Userprofile?.today}
        <h6 className='shade'>Today's Earning</h6>
        </h1>
        </div>
       </div>
       <div className='bux1'>
        <div className='ex1' >
          <p className='hony1'><FaRupeeSign /></p>
          
        </div>
        <div className='subex1'>
        <h1 className='inside1'><FaRupeeSign />{Userprofile?.week}
        <h6 className='shade'>Last 7 Days Earning</h6>
        </h1>
        </div>
       </div>
       <div className='bux2'>
        <div className='ex2' >
          <p className='hony2'><FaRupeeSign /></p>
          
        </div>
        <div className='subex2'>
        <h1 className='inside2'><FaRupeeSign />{Userprofile?.amount}
        <h6 className='shade'>Last 30 Days Earning</h6>
        </h1>
        
        </div>
       </div>
       <div className='bux3'>
        <div className='ex3' >
          <p className='hony3'><FaRupeeSign /></p>
          
        </div>
        <div className='subex3'>
        <h1 className='inside3'><FaRupeeSign />{Userprofile?.all}
        <h6 className='shade'>All Time Earning</h6>
        </h1>
        </div>
       </div>
        </div>
    {/*Updating the Recent sales or refer affiliates in my profile  update later*/}
    <div className='team'>
    
    <h4 className='team_head'> <GiNailedHead className='icon_size'/>    Team Members</h4>
    
    </div>  
    
    </div>
    
  );
};

export default Earning
