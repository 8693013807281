import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { getStorage } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyDNHfwiK3eDjjyu4Q2NntEKMyw4axlHwQg",
    authDomain: "taj-millionaire.firebaseapp.com",
    projectId: "taj-millionaire",
    storageBucket: "taj-millionaire.appspot.com",
    messagingSenderId: "799407135933",
    appId: "1:799407135933:web:89403cf3b246c77dc5ebd4",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app);
export {
    db,
    auth,
    storage
}