import { AuthErrorCodes } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../context/UserAuthContext';

import { db } from '../firebase.config'; // Import your Firestore database
import { collection, addDoc,doc,setDoc,getDocs, query, where } from 'firebase/firestore'; // Import Firestore functions
import './Signup.css';

const Signup = () => {
    const navigate = useNavigate();

    const [nameToSearch, setNameToSearch] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedName, setSelectedName] = useState('');

    const { SignUp } = useAuth();
    const [err, setError] = useState("");
    const [user, setUser] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    });





    
    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        setResults([]);
    
        try {
          const q = query(
            collection(db, 'profile'), // Replace with your collection name
            where('uniqueId', '==', nameToSearch)
          );
    
          const querySnapshot = await getDocs(q);
          const foundResults = [];
    
          querySnapshot.forEach((doc) => {
            foundResults.push({ id: doc.id, ...doc.data() });
          });
    
          setResults(foundResults);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      const handleSelect = (name) => {
        setSelectedName(name); // Set the selected name to the input
        setResults([]); // Clear the results after selection
      };











    const UserHandler = (e) => {
        const { name, value } = e.target;
        setUser((pre) => ({
            ...pre,
            [name]: value
        }));
    };

    const SubmitHandler = async (e) => {
        e.preventDefault();
        const { email,refer,refername, password, confirmPassword } = user;

        if (!email || !password || !confirmPassword) {
            setError("Please fill all fields");
            setTimeout(() => setError(""), 5000);
            return;
        } else if (password !== confirmPassword) {
            setError("Passwords do not match");
            setTimeout(() => setError(""), 5000);
            return;
        } else if (password.length < 6) {
            setError("Password must be at least 6 characters");
            setTimeout(() => setError(""), 5000);
            return;
        }

        const generateUniqueId = (prefix) => {
            const timestamp = Math.floor(Date.now() / 10000000); // Convert to seconds
            const randomNum = Math.floor(Math.random() * 100); 
        return `${prefix}${timestamp}${randomNum}`;
    };

        try {
            const userCredential = await SignUp(email, password);
            const uid = userCredential.user.uid;
              const uniqueId = generateUniqueId('TAJ'); // Replace 'USER' with your desired prefix
            // Save user data, including unique ID, to Firestore
            const userRef = doc(db, 'users',uid); // Change 'users' to your desired collection name
            await setDoc(userRef, {
                uniqueId,
                email,
                uid,
                nameToSearch,
                selectedName,
                createdAt: new Date()
            });

            alert("Signup Successful");
            navigate('/dashboard');
        } catch (err) {
            if (err.code === "auth/email-already-in-use") {
                setError("Email already in use, try another email");
            } else if (err.code === AuthErrorCodes.WEAK_PASSWORD) {
                setError("Password must be at least 6 characters");
            } else {
                setError(err.message);
            }
            setTimeout(() => setError(""), 5000);
        }
    };

    return (
        <div className='box'>
            {err && <p className='error'>{err}</p>}
            <form onSubmit={SubmitHandler} className="form">
                <h2>Signup Taj Millionaire</h2>
                <div className="inputfield">
                    <input type="text" placeholder="Email" value={user.email} name='email' onChange={UserHandler} />
                </div>
                
                

                <div className='inputfield'>
                <input 
        type="text"
        value={nameToSearch}
        onChange={(e) => setNameToSearch(e.target.value)}
        placeholder="Affiliate Id"
      />
      <button onClick={handleSearch}>Search</button>

      {loading && <div>Loading...</div>}
      

      <ul>
        {results.map((result) => (
          <li key={result.id} onClick={() => handleSelect(result.name)} style={{ cursor: 'pointer' }}>
            {result.name}
          </li>
        ))}
      </ul>

      {/* Input field to display the selected name */}
      <input
        type="text"
        value={selectedName}
        onChange={(e) => setSelectedName(e.target.value)}
        placeholder="Referal Name"
      />    
                </div>
                <div className="inputfield">
                    <input type="password" placeholder="Password" value={user.password} name='password' onChange={UserHandler} />
                </div>
                <div className="inputfield">
                    <input type="password" placeholder="Confirm Password" value={user.confirmPassword} name='confirmPassword' onChange={UserHandler} />
                </div>
                
                <div className="inputfield">
                    <input type="submit" />
                </div>
                <p className="forget">Already have an account?<Link to="/login" className="lnh">Login</Link></p>
            </form>
        </div>
    );
};

export default Signup;
