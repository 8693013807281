// src/UserCourses.js
import React from 'react';
import './Enroll.css';
import Header from './Header';

const Enroll = () => {
    const currentCourses = [
        { title: 'Course : Your Course', description: 'Course Description...', status: 'Comming Soon..' },
       
    ];

    

    return (
        
         

        <div className="userhn">
            
            <header className='course_head'>
                <h2 className='learn'>My Learning Platform</h2>
                
            </header>

            <main>
                <h2>Welcome back, User!</h2>

                <section>
                    <h3>Current Courses</h3>
                    {currentCourses.map((course, index) => (
                        <div key={index} className="course-card">
                            <h4>{course.title}</h4>
                            <p>{course.description}</p>
                            <p>Status: {course.status}</p>
                            <button>Continue</button>
                            <button>View Details</button>
                        </div>
                    ))}
                </section>
                <section>
                  <h3 className='note'><strong className='notice'>Note :</strong> wait for Updating your Dashboard Course </h3>
                </section>
               {/*
                <section>
                    <h3>Upcoming Courses</h3>
                    <ul>
                        {upcomingCourses.map((course, index) => (
                            <li key={index}>{course.title} - Starts on {course.startDate}</li>
                        ))}
                    </ul>
                </section>

                        */}
            </main>

            <footer>
                <p>&copy; Taj Millionaire Marketing</p>
                
            </footer>
        </div>
    );
};

export default Enroll;
