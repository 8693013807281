// src/Header.js
import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../context/UserAuthContext';
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { RxDashboard } from "react-icons/rx";


const Header = () => {
   const { logout } = useAuth();
   const navigate = useNavigate();
   const [isMenuOpen, setIsMenuOpen] = useState(false);

   const userlogout = async () => {
      try {
         await logout();
         navigate('/home');
      } catch (error) {
         console.log(error);
      }
   };

   const toggleMenu = () => {
      setIsMenuOpen((prev) => !prev);
   };

   let activeStyle = {
      textDecoration: "hover",
   };

   return (
      <header className='header'>
         <div className='logos'>
            <img src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2F1726219994035-logo.png?alt=media&token=2c1e7e4f-e5d7-40fd-8b92-ac40d202c62a"></img>
         </div>
         <button className="menu-toggle" onClick={toggleMenu}>
            {isMenuOpen ? <RxCrossCircled /> : <AiOutlineMenuUnfold />}
         </button>
         <nav className={`link ${isMenuOpen ? 'open' : ''}`}>
            <ul className='ul'>
            <li >
                  <NavLink className='alphat'
                     to="/Userhome"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     Home
                  </NavLink>
               </li>
               <li>
                  <NavLink className='alphat'
                     to="/dashboard"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     My Courses
                  </NavLink>
               </li>
               <li>
                  <NavLink className='alphat'
                     to="/profile"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     Profile
                  </NavLink>
               </li>
               <li>
                  <NavLink className='alphat'
                     to="/earning"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     Dashboard
                  </NavLink>
               </li>
               <li> 
                  <NavLink className='alphat'
                     to="/commission"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     Commission Chart
                  </NavLink>
               </li>
               <li>
                  <NavLink className='alphat'
                     to="/company_info"
                     style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  >
                     Company Info
                  </NavLink>
               </li>
               <li>
                  <button className="active button" onClick={userlogout}>
                     Logout
                  </button>
               </li>
            </ul>
         </nav>
      </header>
   );
};

export default Header;
