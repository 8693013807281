import React, { useState } from 'react';
import { db } from '../firebase.config'; // Adjust the import based on your config file
import { collection, getDocs } from 'firebase/firestore';
import './Admin.css'; // Import your CSS file if you have one

const Admin = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState([]);

    const fetchDocuments = async () => {
        setLoading(true);
        setError(null); // Reset error state before fetching
        try {
            const querySnapshot = await getDocs(collection(db, 'profile')); // Replace with your collection name
            const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Extract document data and id
            setDocuments(docs);
            setFilteredDocuments(docs); // Set initial filtered documents
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        const filtered = documents.filter(doc =>
            (doc.holder && doc.holder.toLowerCase().includes(searchTerm.toLowerCase())) || // Search by holder
            (doc.account && doc.account.toLowerCase().includes(searchTerm.toLowerCase())) || // Search by account
            (doc.ifsc && doc.ifsc.toLowerCase().includes(searchTerm.toLowerCase())) || // Search by IFSC
            (doc.amount && doc.amount.toString().includes(searchTerm)) // Search by amount
        );
        setFilteredDocuments(filtered);
    };

    return (
        <div className="admin-container">
            <h2 className='data'>Admin Panel</h2>
            <button onClick={fetchDocuments} disabled={loading} className="fetch-button">
                {loading ? 'Fetching...' : 'Fetch Documents'}
            </button>
            {error && <div className="error-message">Error fetching documents: {error.message}</div>}

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                <button onClick={handleSearch} className="search-button">Search</button>
            </div>

            {filteredDocuments.length > 0 ? (
                <div className="documents-list">
                    {filteredDocuments.map(doc => (
                        <div key={doc.id} className="document-item">
                            <div><strong>Holder:</strong> {doc.holder || 'N/A'}</div>
                            <div><strong>Account:</strong> {doc.account || 'N/A'}</div>
                            <div><strong>Bank Name:</strong> {doc.bank || 'N/A'}</div>
                            <div><strong>IFSC:</strong> {doc.ifsc || 'N/A'}</div>
                            <div><strong>Amount:</strong> {doc.amount !== undefined ? doc.amount : 'N/A'}</div>
                            <hr />
                        </div>
                    ))}
                </div>
            ) : (
                <div>No documents found.</div>
            )}
        </div>
    );
};

export default Admin;
