import React from "react";
import "./Footer.css";
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { FaWhatsapp } from "react-icons/fa6";



const Footer=()=>{
    return (
        <div className="footer">
            { /* <img src="logo.png" alt="logo_footer"></img> */ }
            <div className="sb_footer section_padding">
                <div className="sb_footer-links">
                <div className="sb_footer-links_div">
                    <h4>Company</h4>
                    <a href="/about">
                        <p>About</p>
                    </a>
                    <a href="/courses">
                        <p>Courses</p>
                    </a>
                    <a href="/login">
                        <p>Login</p>
                    </a>
                
                    <a href="/contact">
                        <p>contact</p>
                    </a>
           </div>
                
                <div className="sb_footer-links_div">
                    <h4 className="company">Taj Millionaire</h4>
                    <div className="logo1">
                        <img className="lgrs" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2F1726220562001-logo.png?alt=media&token=8a1bac8a-01bf-45c1-81f7-bbb6e2437754"></img>
                    </div>
                   
                </div>
                
                                
                <div className="sab_footer-lins_div">
                    <h4>Lets Connect With us</h4>
                    <div className="socialmeadia">
                        
                        <p ><SlSocialInstagram />  <a href="https://www.instagram.com/taj_millionaire_marketing_/?igsh=MWxnamttd2dzd2NqYw%3D%3D" rel="noopener noreferrer" className="handle">  Instagram</a></p>
                        <p ><FaWhatsapp /><a href="https://whatsapp.com/channel/0029VaovXkB0rGiQ2mGbW33w" rel="noopener noreferrer" className="handle"> Whatsapp Channel</a></p>
                        <p ><TiSocialYoutube />   <a href="https://www.youtube.com/@tajmillionairemarketing" rel="noopener noreferrer" className="handle"> YouTube Channel</a></p>
                    </div>
                </div>
            </div>

            <hr></hr>

            <div className="sb_footer-below">
                <div className="sb_footer-copyright">
                    <p>
                        @{new Date().getFullYear()} Taj Millionaire. All right reserved.
                    </p>
                </div>
                <div className="sb_footer-below-links">
                    <a href="/terms"><div><p>Terms & Conditions</p></div></a>
                    <a href="/privacy"><div><p>Privacy</p></div></a>
                    <a href="/find"><div><p>Security</p></div></a>
                    <a href="/cookie"><div><p>Cookie Declaration</p></div></a>
                
                </div>
            </div>
            </div>
            </div>
    )
}

export default Footer;